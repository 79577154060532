"use client";

import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useRouter, useSearchParams } from "next/navigation";

import { useAuth } from "@auth/client-sdk-react";
import { MagicLinkCreationResult } from "@auth/client-sdk";

import Button from "../../base/Button/Button";
import Text from "../../base/Text/Text";
import Spacer from "../../layout/Spacer/Spacer";
import { AlertPrompt } from "../AlertPrompt/AlertPrompt";
import Column from "../../layout/Column/Column";

interface IMagicLinkLoginProps {
  email: string;
  disabled?: boolean;
  setDisabled(): void;
}

enum MagicLinkState {
  READY,
  SENDING,
  NOT_FOUND,
  OTHER_ERROR,
  SUCCESS,
}

export const MagicLinkLogin: FC<IMagicLinkLoginProps> = ({
  email,
  disabled,
  setDisabled,
}) => {

  const auth = useAuth();

  const router = useRouter();
  const searchParams = useSearchParams();

  const onSubmit = useCallback(async () => {
    setState(MagicLinkState.SENDING);
    const postLogin = searchParams.get("postLogin") as string | undefined;
    const result = await auth.sendMagicLink(email, postLogin);

    if (result === MagicLinkCreationResult.SUCCESS) {
      setState(MagicLinkState.SUCCESS);
      setDisabled();
    } else if (result === MagicLinkCreationResult.NOT_FOUND) setState(MagicLinkState.NOT_FOUND);
    else if (result === MagicLinkCreationResult.OTHER_ERROR) setState(MagicLinkState.OTHER_ERROR);
  }, [ email, auth, setDisabled, router ]);

  const [ state, setState ] = useState(MagicLinkState.READY);

  // Whenever email changes, reset the state.
  // This is to remove error message in case of typo.
  useEffect(() => {
    setState(MagicLinkState.READY);
  }, [ email ]);

  return (
    <Column component={MagicLinkLogin.name} align="center">
      { state === MagicLinkState.SUCCESS && (
        <AlertPrompt
          style="success"
          title="Email with login link sent!" hideImage={false} >
          <Text size="2xs" align="center">
            We&apos;ve sent you a login link to { email }. Please also check your spam folder.
          </Text>
        </AlertPrompt>
      ) }
      { state === MagicLinkState.OTHER_ERROR && (
        <AlertPrompt
          style="error"
          title="OOPS!" hideImage={false} >
          <Text size="2xs" align="center">
            Sorry, something went wrong. Please try again later.
          </Text>
          <Spacer size="md" />
        </AlertPrompt>
      ) }
      { state === MagicLinkState.NOT_FOUND && (
        <AlertPrompt
          style="error"
          title="OOPS!" hideImage={false} >
          <Text size="2xs" align="center">
            It looks like you don’t have an account with us yet.
            Tell us about your cat and start feeding fearlessly.
            Love hard. Feed Fresh.
          </Text>
          <Spacer size="md" />
          <Button to="/sign-up">
            GET STARTED
          </Button>
        </AlertPrompt>
      ) }
      { (state !== MagicLinkState.SUCCESS) && (
        <Button
          onClick={onSubmit}
          disabled={state === MagicLinkState.SENDING || disabled}
        >
          { state === MagicLinkState.SENDING
            ? "SENDING YOUR LINK..."
            : "EMAIL ME A LOGIN LINK"
          }
        </Button>

      ) }
    </Column>
  );
};

/* eslint-disable @next/next/no-html-link-for-pages */
import {
  FC,
  Suspense,
  useCallback,
  useState,
} from "react";
import Axios from "axios";
import { useRouter, useSearchParams } from "next/navigation";

import { useTextInput, useToggle } from "@/hooks";
import { useAuth } from "@auth/client-sdk-react";

import Button from "../../base/Button/Button";
import Input from "../../form/Input/Input";
import Spacer from "../../layout/Spacer/Spacer";
import { AlertPrompt } from "../AlertPrompt/AlertPrompt";
import Text from "../../base/Text/Text";
import Column from "../../layout/Column/Column";

interface IPasswordLoginProps {
  email: string;
}

enum LoginState {
  READY,
  ATTEMPTING,
  INVALID_CREDENTIALS,
  SERVER_ERROR,
}

export const PasswordLogin: FC<IPasswordLoginProps> = ({ email }) => {

  const auth = useAuth();
  const searchParams = useSearchParams();

  const [ password, onPasswordChange ] = useTextInput();

  // const validEmail = isEmail(email);

  const [ state, setState ] = useState(LoginState.READY);

  const [ rememberMe, toggleRememberMe ] = useToggle(true);

  const router = useRouter();

  const onSubmit = useCallback(async () => {
    setState(LoginState.ATTEMPTING);
    try {
      const postLogin = searchParams.get("postLogin");
      const redirectAfterLogin = postLogin as string ?? "/customer";
      await auth.login(email, password, rememberMe, redirectAfterLogin);
    } catch (err) {
      if (Axios.isAxiosError(err)) {
        if (err?.response?.status === 401) setState(LoginState.INVALID_CREDENTIALS);
        else setState(LoginState.SERVER_ERROR);
      } else setState(LoginState.SERVER_ERROR);
    }
  }, [ password, email, auth, rememberMe, router ]);

  const failed = state === LoginState.INVALID_CREDENTIALS || state === LoginState.SERVER_ERROR;

  return (
    <Column align="center" className="w-full">
      <Suspense>
        <Input
          value={password}
          onChange={onPasswordChange}
          className="md:w-96 w-full"
          name="Password"
          label="Password"
          placeholder="Password"
          type="password"
        />
      </Suspense>

      { state === LoginState.INVALID_CREDENTIALS && (
        <>
          <Spacer size="md" />
          <AlertPrompt
            style="error"
            title="Incorrect email or password" hideImage={false} />
          <Spacer size="sm" />
        </>
      ) }
      { state === LoginState.SERVER_ERROR && (
        <>
          <Spacer size="md" />
          <AlertPrompt
            style="error"
            title="Something went wrong" hideImage={false}>
            <Text size="2xs" align="center">
              { "Please try again later or reach our to our customer support if you're still having issues" }
            </Text>
          </AlertPrompt>
          <Spacer size="sm" />
        </>
      ) }
      { !failed && (
        <Spacer size="lg" />
      ) }
      <Button
        onClick={onSubmit}
        disabled={ password.length < 6 || state === LoginState.ATTEMPTING}
        className="w-[300px]"
      >
        { state !== LoginState.ATTEMPTING ? "Log in" : "Logging in..." }
      </Button>
      <Spacer size="lg" />
      <Column
        className="w-full md:w-96"
        justify="between"
        align="center"
        direction="row"
      >
        <label htmlFor="remember-me-checkbox" className="flex flex-row items-center">
          <input
            id="remember-me-checkbox"
            type="checkbox"
            checked={rememberMe}
            onChange={toggleRememberMe}
            className="accent-black w-4 h-4 mr-1"
          />
            Remember me
        </label>
        <a href="https://join.katkin.com/recover-password" className="font-display underline text-sm">
          Forgot your password?
        </a>
      </Column>
    </Column>
  );
};
